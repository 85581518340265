import {DecimalPipe} from '@angular/common';
import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {AllQuicksaleService} from '../../@shared/quick-sale/all-quick-sale/all-quicksale.service';
import {SortableDirective, SortEvent} from './sortable.directive';
import {CitiesCallCenter, DeliveryStatus, ListeningBinotel, Orders, OrderStatus} from './interface';
import {
    NgbCalendar,
    NgbDate,
    NgbDateParserFormatter,
    NgbModal, NgbModalConfig
} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {NewDocumentService} from '../../@shared/quick-sale/new-document/new-document.service';
import {ExcelExportService} from '../../@shared/excel.export.service';
import {Alltoasts} from '../../toasts/alltoasts';
import {CashAccountingCheckboxService} from "../../cash-accounting-checkbox/service/cash-accounting-checkbox.service";
import {switchMap, takeUntil} from "rxjs/operators";
import {CheckboxOrganization} from "../../cash-accounting-checkbox/interface/cash-accounting.interface";
import {Unsubscribe} from "../../@shared/unsubscribe";
import {NavbarService} from "../../@shared/navbar/navbar.service";
import {ExcelWhithoutPhoneService} from "../../@shared/excel.without-phone";
import {PossibleDelaysService} from "../../possible-delays/service/possible-delays.service";
import {PossibleDelays} from "../../possible-delays/interface/possible-delays.interface";


@Component(
    {
        selector: 'app-quick-sale-documents',
        templateUrl: './all-quicksale.html',
        styleUrls: ['./all-quicksale.component.scss'],
        providers: [DecimalPipe]
    })
export class TableCompleteComponent extends Unsubscribe implements OnInit, OnDestroy {

    constructor(public service: AllQuicksaleService,
                private calendar: NgbCalendar,
                private settingNgM: NgbModalConfig,
                public formatter: NgbDateParserFormatter,
                public DocService: NewDocumentService,
                private cashAccountingCheckboxService: CashAccountingCheckboxService,
                public navbarService: NavbarService,
                public possibleDelaysService: PossibleDelaysService,
                private router: Router,
                public modalService: NgbModal,
                public excel: ExcelExportService,
                private Alltoasts: Alltoasts,
                private excelService: ExcelWhithoutPhoneService,
    ) {
        super();
        settingNgM.backdrop = 'static';
        this.fromDate = calendar.getToday();
        this.countries$ = service.orders$;
        this.total$ = service.total$;
    }

    dateForReport: NgbDate | null;
    placeForReport: number;
    checkboxOrganizationId: number;
    countries$: Observable<Orders[]>;
    total$: Observable<number>;
    cityLocal: string = localStorage.getItem('city');
    place: string = localStorage.getItem('place');
    prof: string = localStorage.getItem('profs');
    group: string = localStorage.getItem('group');
    fromDate: NgbDate | null;
    idWS: number;
    cashier: boolean;
    allOrders = false;
    hoveredDate: NgbDate | null = null;
    orderId: number;
    fromDateModal: NgbDate | null;
    toDate: NgbDate | null;
    shiftCollectionId: number;
    id: number;
    address: string;
    time: string;
    phone: string;
    deliveryStatusId: number;
    orderDeliveryStatus: DeliveryStatus;
    binotel: ListeningBinotel[];
    citiesCallCenter: CitiesCallCenter;
    newDocumentMode: string;
    newDocumentId: string;
    order = {
        id: null,
        order_number: null,
        author_name: null,
        author_surname: null,
        status: null,
        city_id: null,
        phone: null,
        checkbox_id: null,
        checkbox_status: null
    };
    idCheckBoxByOrder: string;
    loader: boolean;
    orderNumber: number;
    checkboxOrganization: CheckboxOrganization[];
    orderStatusEnum = OrderStatus;
    checkId: number;
    orderInfo: any;
    public possibleDelays: PossibleDelays[];


    citiesArrayIds = [];
    @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
    @ViewChild('audioOption') audioPlayerRef: ElementRef;

    onSort = ({column, direction}: SortEvent) => {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });
        this.service.sortColumn = column;
        this.service.sortDirection = direction;
    };

    applyStatus = (id, content) => {
        this.service.applyStatus(id, content);
    }

    printCheckBox(id, content, order): void {
        this.idCheckBoxByOrder = id;
        this.orderNumber = order;
        this.modalService.open(content);
    }

    updateList = () => {
        const getAllOrders = this.service.fetchAll(this.cashier, this.allOrders, this.citiesArrayIds).subscribe(val => {
            }, () => {
            }, () => {
                getAllOrders.unsubscribe();
            }
        );
    };

    modeCashier(event, mode: string): void {
        switch (mode) {
            case 'cashier':
                this.service._cashier = event;
                break;
            case 'all':
                this.service._filterAll = event;
                break;
        }
        this.changePlace();
    }

    toCourierControl = (id) => {
        const toCC = this.service.toCourierControl(id).subscribe(
            () => {
            },
            () => {
            },
            () => toCC.unsubscribe()
        );
    };


    ngOnInit(): void {
        this.DocService.getAllPlaces().subscribe(() => {
        }, () => {
        }, () => {
            this.getCityCallCenter();
            this.service.placeFromSelect = localStorage.getItem('place');
        });
        this.service.onlyOrderNumber = false;
        this.getCheckboxOrganization();
        this.getPossibleDelays();

        interval(150000)
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => this.getPossibleDelays());
    }

    ngOnDestroy(): void {
        this.modeCashier(false, 'cashier');
    }

    closeModal = () => {
        this.modalService.dismissAll();
    };
    openModal = (content, id, address, phone, time) => {
        this.id = id;
        this.address = address;
        this.phone = phone;
        this.time = time;
        this.modalService.open(content);
    };
    confirmModal = () => {
        this.service.updateOrderFromWS(this.id, 'canceled').subscribe({
            complete: () => this.closeModal()
        });
    };

    changePlace = () => {
        this.service.paramsForOrders.lastUpdatedAt = '';
        this.service.paramsForOrders.lastId = '';
        this.updateList();
    };
    onPrint = (id, content) => {
        this.orderId = id;
        this.modalService.open(content, {scrollable: true});
    };
    confirmFromSiteOrders = (id?, city?, place?) => {
        if (city && place) {
            localStorage.setItem('city', city);
            localStorage.setItem('place', place);
        }
        if (id) {
            this.idWS = id;
        }
        this.service.checkOrderFromWS(this.idWS).subscribe(() => {
        }, () => {
        }, () => {

        });
        this.closeModal();
    };

    openInNewTab = (mode, id, city?, place?) => {
        if (city) {
            this.changeCityQS(city, place);
            this.closeModal();
        }
        this.router.navigate([`/quick-sale/${mode}/${id}`]).then(r => {
        });
    }
    open = (content, id?, city?, place?) => {
        if (city && place) {
            localStorage.setItem('city', city);
            localStorage.setItem('place', place);
            this.getCityInfo();
        }
        if (id) {
            this.idWS = id;
        }
        this.modalService.open(content);
    }
    getCityInfo(): void {
        this.navbarService.getCityInfo().subscribe({
            next: value => {
                localStorage.setItem('cityInfo', JSON.stringify(value.data));
            }
        });
    }
    deleteOrderConfirm = (id, content) => {
        this.modalService.open(content);
        this.checkId = id;
    }

    deleteOrder(): void{
        this.DocService.deleteOrder(this.checkId);
    }
    
    reportSale = content => {
        this.modalService.open(content, {size: 'xl', scrollable: true});
    }

    onDateSelection(date: NgbDate, d: any): void {
        if (!this.fromDateModal && !this.toDate) {
            this.fromDateModal = date;
        } else if (this.fromDateModal && !this.toDate && date) {
            this.toDate = date;
            d.close();
        } else {
            this.toDate = null;
            this.fromDateModal = date;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDateModal && !this.toDate && this.hoveredDate && date.after(this.fromDateModal) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDateModal) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDateModal) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    orderStatus = (id: number, content, orderNumber) => {
        this.deliveryStatusId = orderNumber;
        this.service.deliveryStatus(id)
            .subscribe({
                next: value => {
                    this.orderDeliveryStatus = value;
                },
                complete: () => this.modalService.open(content)
            });
    }

    openNewWindow = (url) => {
        window.open(url, '_blank');
    }
    openHistory = () => {
        window.open('/quick-sale/history-orders', '_blank');
    }

    openDelays = () => {
        window.open('/possible-delays', '_blank');
    }

    listeningBinotel = (city, phone, content, size, orderNumber) => {
        this.deliveryStatusId = orderNumber;
        this.service.listeningBinotel(city, phone)
            .subscribe({
                next: value => this.binotel = value,
                complete: () => this.modalService.open(content, {size})
            });
    }
    changeCityQS = (city, place) => {
        localStorage.setItem('city', city);
        localStorage.setItem('place', place);
        this.service.placeFromSelect = place;
        this.DocService.getAllPlaces().subscribe(() => {
        }, () => {
        }, () => {

            this.service.fetchAll('', '', this.citiesArrayIds).subscribe({
                complete: () => {
                    this.countries$ = this.service.orders$;
                }

            });
        });
        this.getCityCallCenter();

    };
    changeCity = (mode: string, id: string, content, size?) => {
        this.newDocumentMode = mode;
        this.newDocumentId = id;
        this.service.getCitiesCallCenter().subscribe({
            next: value => this.citiesCallCenter = value,
            complete: () => {
                this.modalService.open(content);
            }
        });
    };
    getCityCallCenter = () => {
        this.service.getCitiesCallCenter().subscribe({
            next: value => this.citiesCallCenter = value,
            complete: () => {
                this.cityLocal = localStorage.getItem('city');
                this.citiesArrayIds = [];
                if (this.group !== '0') {
                    this.citiesCallCenter.cities.filter(item => this.citiesArrayIds.push(item.id));
                }
                this.service.fetchAll('', '', this.citiesArrayIds).subscribe(() => {
                });
            }
        });
    }
    checkCurrentState = (id, city, place, orderDone, orderCancelled, content?, mode?) => {
        if (mode) {
            city = localStorage.getItem('city');
            place = localStorage.getItem('place');
        }
        let order;
        this.service.getOrdersNew(id).subscribe({
            next: value => order = value,
            complete: () => {
                switch (order.state) {
                    case 'processing':
                        if (content) {
                            this.open(content, id, city, place);
                        } else {
                            this.confirmFromSiteOrders(id, city, place);
                        }
                        break;
                    case 'done':
                        this.modalService.open(orderDone);
                        break;
                    case 'canceled':
                        this.modalService.open(orderCancelled);
                        break;
                    case 'pending':
                        this.confirmFromSiteOrders(id, city, place);
                        break;
                }
            }
        });
    }

    contextMenu(content, id, orderNumber, authorName, authorSurname, status, cityId, phone, checkboxId, checkboxStatus): void {
        this.order.id = id;
        this.order.order_number = orderNumber;
        this.order.author_name = authorName;
        this.order.author_surname = authorSurname;
        this.order.status = status;
        this.order.city_id = cityId;
        this.order.phone = phone;
        this.order.checkbox_id = checkboxId;
        this.order.checkbox_status = checkboxStatus;
        this.modalService.open(content, {size: 'xl'});
    }
    close(): void {
        this.modalService.dismissAll();
    }

    sendRRO(id, content, orderNumber): void {
        this.loader = true;
        this.service.sendCheckBox(id).subscribe({
            next: value => this.service.checkBox = value,
            complete: () => {
                this.idCheckBoxByOrder = this.service.checkBox.data.id;
                this.orderNumber = orderNumber;
                if (this.service.checkBox.data.status === 'CREATED') {
                    this.service.checkStatusRRO(content, this.idCheckBoxByOrder);
                    this.loader = false;
                } else if (this.service.checkBox.data.status === 'DONE') {
                    this.modalService.open(content);
                    this.loader = false;
                } else if (this.service.checkBox.data.status === 'ERROR') {
                    this.Alltoasts.showDanger('Виникла помилка, відправте повторно до РРО');
                    this.loader = false;
                }
            }
        });
    }

    TotalWithRound = (total) => {
        if (JSON.parse(localStorage.getItem('cityInfo')).country.total_round === 1) {
            return Math.ceil(total);
        } else {
            return total;
        }
    }

    getCheckboxOrganization(): void {
        this.cashAccountingCheckboxService.getCheckboxOrganization()
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: (value) => {
                    this.checkboxOrganization = value.data;
                },
            });
    }

    putCallBackRequest(newStatus: OrderStatus, id: number): void {
        const requestBody = { status: newStatus };
        this.service.putCallBackRequest(id, requestBody)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.service.ordersFromSite(this.citiesArrayIds).subscribe();
                }
            });
    }

    exportToExcel(excel: string): void {
        this.excelService.excel(excel);
    }

    liqpayStatus(status: string): string{
        switch (status) {
            case 'error':
                return 'Неуспішний платіж. Некоректно заповнені дані';
            case 'success':
                return ('Успішний платіж.');

            case 'subscribed':
                return ('Підписка успішно оформлена.');

            case 'failure':
                return ('Неуспішний платіж. Некоректно заповнені дані.');

            case 'reversed':
                return ('Платіж повернений.');

            case 'unsubscribed':
                return ('Підписка успішно деактивована.');

            case '3ds_verify':
                return ('Потрібна 3DS верифікація. Для завершення платежу, потрібно виконати 3ds_verify.');

            case 'captcha_verify':
                return ('Очікується підтвердження captcha.');

            case 'cvv_verify':
                return ('Потрібне введення CVV картки відправника. Для завершення платежу, потрібно виконати cvv_verify.');

            case 'ivr_verify':
                return ('Очікується підтвердження дзвінком ivr.');

            case 'otp_verify':
                return ('Потрібне OTP підтвердження клієнта. OTP пароль відправлений на номер телефону Клієнта. Для завершення платежу, потрібно виконати otp_verify.');

            case 'password_verify':
                return ('Очікується підтвердження пароля додатка Приват24.');

            case 'phone_verify':
                return ('Очікується введення телефону клієнтом. Для завершення платежу, потрібно виконати phone_verify.');

            case 'pin_verify':
                return ('Очікується підтвердження pin-code.');

            case 'receiver_verify':
                return ('Потрібне введення даних одержувача. Для завершення платежу, потрібно виконати receiver_verify.');

            case 'sender_verify':
                return ('Потрібне введення даних відправника. Для завершення платежу, потрібно виконати sender_verify.');

            case 'senderapp_verify':
                return ('Очікується підтвердження в додатку Privat24.');

            case 'wait_qr':
                return ('Очікується сканування QR-коду клієнтом.');

            case 'wait_sender':
                return ('Очікується підтвердження оплати клієнтом в додатку Privat24/SENDER.');

            case 'cash_wait':
                return ('Очікується оплата готівкою в ТСО.');

            case 'hold_wait':
                return ('Сума успішно заблокована на рахунку відправника.');

            case 'invoice_wait':
                return ('Інвойс створений успішно, очікується оплата.');

            case 'prepared':
                return ('Платіж створений, очікується його завершення відправником.');

            case 'processing':
                return ('Платіж обробляється.');

            case 'wait_accept':
                return ('Кошти з клієнта списані, але магазин ще не пройшов перевірку. Якщо магазин не пройде активацію протягом 60 днів, платежі будуть автоматично скасовані.');

            case 'wait_card':
                return ('Не встановлений спосіб відшкодування у одержувача.');

            case 'wait_compensation':
                return ('Платіж успішний, буде зарахований в щодобовій проводці.');

            case 'wait_lc':
                return ('Акредитив. Кошти з клієнта списані, очікується підтвердження доставки товару.');

            case 'wait_reserve':
                return ('Грошові кошти за платежем зарезервовані для проведення повернення за раніше поданою заявкою.');

            case 'wait_secure':
                return ('Платіж на перевірці.');

            default:
                return ('Очікується');
        }
    }

    openQualityControl(order, modal): void {
        this.orderInfo = order;
        this.modalService.open(modal, {size: 'xl'});
    }

    private getPossibleDelays(): void {
        this.possibleDelaysService.getPossibleDelays()
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: value => this.possibleDelays = value.data,
            });
    }

    get isDelayRecallEmpty(): boolean {
        if (this.possibleDelays){
            return this.possibleDelays.some(item => item.delay_recall.length === 0);
        }
    }
}
